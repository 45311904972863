import Vue from 'vue';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import Validate from 'vuelidate';
import VueToastr from 'vue-toastr';
import VueCookies from 'vue-cookies';
import moment from 'moment';
import VideoBackground from 'vue-responsive-video-background-player';
import Particles from 'particles.vue';
import excel from 'vue-excel-export';
import VueTour from 'vue-tour';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import VueParticles from 'vue-particles';
import vueAwesomeCountdown from 'vue-awesome-countdown';

import App from './App.vue';
import router from './router';
import './design/scss/app.scss';

import '@/assets/scss/main.scss';

require('vue-tour/dist/vue-tour.css');
const numeral = require('numeral');

Vue.use(VueTour);
Vue.use(excel);
Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;
Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(Validate);

Vue.use(VueParticles);

Vue.use(BootstrapVue);

Vue.use(VueCookies);

Vue.use(Particles);

Vue.component('video-background', VideoBackground);

Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.mixin({
    methods: {
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        TruncateToDecimals2(num, type, dec = 2) {
            //   if (!dec) dec = 2;
            const numberFormat = parseFloat(num.toFixed(10));
            const d = numberFormat.toString().split('.');
            if (type === 1) {
                return `${numeral(d[0]).format(0, 0)}`;
            }
            return `${numeral(d[0]).format(0, 0)}${
                d[1] ? `.${d[1].slice(0, dec)}` : ''
            }`;
        },
        getDateTime(unix) {
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime2(unix) {
            return moment(unix).utcOffset('+00:00').format('DD/MM/YYYY');
        },
        getDateTime3(date) {
            const unix = Date.parse(date);
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime4(unix) {
            return moment(unix * 1000)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY');
        },
        getDateTime5(date) {
            const unix = Date.parse(date);
            return moment(unix).utcOffset('+00:00').format('DD/MM');
        },
        getDateTime6(date) {
            const unix = Date.parse(date);
            return moment(unix).utcOffset('+00:00').format('DD/MM/YYYY');
        },
        messageErrorToastr(message) {
            this.$toastr.e(
                message.replace('Returned error: execution reverted: ', ''),
                'Failed',
            );
        },
        getDateTime7(date) {
            const unix = Date.parse(date);
            return moment(unix).utcOffset('+00:00').format('YYYY/MM/DD, LT');
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        isNaN(x) {
            // eslint-disable-next-line no-self-compare
            return x !== x;
        },
        toPlainString(num) {
            return `${+num}`.replace(
                /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
                (a, b, c, d, e) => {
                    return e < 0
                        ? `${b}0.${Array(1 - e - c.length).join(0)}${c}${d}`
                        : b + c + d + Array(e - d.length + 1).join(0);
                },
            );
        },
        async getLimitGas(nft) {
            let ratio = 1.5;
            if (nft) {
                ratio = 3;
            }
            const { web3 } = this.$store.state.contract;
            const block = await web3.provider.eth.getBlock('latest');
            const gasLimit = block.gasLimit / block.transactions.length;
            let currentGas = parseInt(gasLimit * ratio, 10);
            if (currentGas < 2000000 || currentGas > 10000000) {
                currentGas = parseInt(
                    1000000 * (Math.random() * (3.5 - 1.5) + 1),
                    10,
                );
            }
            console.log('gas', currentGas);
            return currentGas;
        },
        getSummon(index, max, version) {
            const { hero } = this.$store.state.contract.marketplace;
            if (version.toLowerCase() !== hero.addr.toLowerCase()) {
                return `${index}`;
            }
            return `${index}/${max}`;
        },
        getImageHero(type, name, version) {
            let url;
            const { hero } = this.$store.state.contract.marketplace;
            if (version && version.toLowerCase() !== hero.addr.toLowerCase()) {
                url = `https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/${name}.png?v=1`;
                return url;
            }
            if (type === '3d' || type === '3d-raw') {
                url = `https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${type}/${name}.png?v=1`;
                return url;
            }
            if (type === 'full') {
                url = `https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${type}/${name}.png?v=1`;
                return url;
            }
            url = `https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${name}.png?v=1`;
            return url;
        },
    },
});

// Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
