<template>
    <router-view />
</template>
<script>
import { mapMutations } from 'vuex';

const Web3 = require('web3');

export default {
    components: {},
    data() {
        return {
            s500: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
    },
    watch: {
        $route(to) {
            document.title = to.meta.title
                ? `HeroBook | ${to.meta.title}`
                : 'HeroBook Marketplace';
            this.isOpenNavigation = false;
            window.scrollTo(0, 0);

            this.onLoad();

            // simulation loading assets
            setTimeout(() => {
                this.outLoad();
            }, 800);
        },
        isLogin: {
            handler(newVal) {
                if (newVal) {
                    this.getInfoUser();
                }
            },
        },
    },
    created() {
        this.$store.state.contract.web3.provider = new Web3(
            this.$store.state.contract.provider.link,
        );
        this.$store.dispatch('core/req_getFee');
        this.$store.dispatch('core/req_getFileGame');
        const userRaw = this.$store.getters['auth/loggedIn'];
        if (userRaw !== undefined && userRaw !== null) {
            this.$store.dispatch('info/req_getInfo');
            this.$store.dispatch('info/req_getBalance');
            this.$store.dispatch('info/req_infoSwap');
        }

        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'auth/REGISTER_SUCCESS':
                case 'auth/FORGOT_SUCCESS':
                case 'auth/LOGOUT_SUCCESS':
                    this.outLoad();
                    this.$router.push({ name: 'Login' });
                    break;
                case 'auth/LOGIN_SUCCESS':
                    this.$toastr.s('Login Success', 'Successfully');
                    this.onLoad();
                    setTimeout(() => {
                        this.$store.dispatch('info/req_getInfo');
                        this.$store.dispatch('info/req_getBalance');
                        if (this.$route.name === 'Login') {
                            this.$router.push({
                                name: 'Member',
                            });
                        }
                        this.outLoad();
                    }, 1000);

                    break;
                case 'info/GET_INFO_SUCCESS':
                    this.outLoad();
                    break;
                case 'core/SUCCESS_MESSAGE':
                    this.outLoad();
                    this.$toastr.s(
                        this.$store.state.core.success,
                        'Successfully',
                    );

                    break;
                case 'core/ERROR_MESSAGE':
                    this.outLoad();
                    this.$toastr.e(this.$store.state.core.error, 'Oops!');

                    break;
                case 'core/ERROR_500':
                    this.outLoad();
                    this.s500 = true;

                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        document.title = 'HeroBook Marketplace';
        this.unsubscribe();
    },
};
</script>
<style scoped>
html {
    scroll-behavior: smooth;
}
</style>
